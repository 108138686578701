import React, {useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import BookConsultation from "../../../../components/pages/BookConsultation";
import PivotAccessOptions from "../../../../components/products/PivotAccessOptions";
import BANManagement from "../../../../components/pages/BANManagement";
import FaqSection from "../../../../components/pages/FaqSection";

const IndexPage = () => {

  const {t} = useTranslation();
  const [whichRollover, setWhichRollover] = useState('none');

  const handleWhichRollover = (which) => {
    let whichRoll = which.target.dataset.roll;
    setWhichRollover(whichRoll);
  }
  const handleWhichRollOut = () => {
    setWhichRollover('none');
  }

  return (
      <Layout>
        <Seo
            title={t('products_pivot')}
            description={t('des_products_pivot')}
        />
        <div className="products pivot-page">
          <section className={'hero-pages'}>
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/pivot/logo-gray-pivot.png"
                        loading={'lazy'}
                        width={139}
                        height={50}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Pivot logo"
                    />
                    <h1 dangerouslySetInnerHTML={{__html:t('pivot_headline')}} />
                    <p dangerouslySetInnerHTML={{__html:t('pivot_headline2_copy')}} />
                    <BookConsultation/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/pivot/pivot-hero.jpg"
                        loading={'lazy'}
                        width={555}
                        height={423}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Pivot locker"
                        className={'curve-family'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="section-delimeter container"/>
          <section className={'appearance-functionality'}>
            <PivotAccessOptions/>
            <br/>
          </section>
          <div className="section-delimeter container"/>

          <section className="pivot-power">
            <div className="container">
              <h1 dangerouslySetInnerHTML={{__html:t('pivot_power_headline')}} />
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div className="parent">
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html: t('product_highlights')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html:t('hardwired_smart_locker_system')}} />
                        <li dangerouslySetInnerHTML={{__html:t('external_led_indicator_for_lock_use_status')}} />
                        <li dangerouslySetInnerHTML={{__html:t('internal_usb_for_charging_devices')}} />
                        <li dangerouslySetInnerHTML={{__html:t('interior_led_light_to_illuminate_storage_area')}} />
                        <li dangerouslySetInnerHTML={{__html:t('hook_latch_for_push_opening_no_handle_required')}} />
                        <li dangerouslySetInnerHTML={{__html:t('ic_fcc_cc_and_bifma_certified_as_required')}} />
                      </ul>
                      <h3 dangerouslySetInnerHTML={{__html: t('Access_Options')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('rfid')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Mobile_Access')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('kiosk_keypad')}}/>
                      </ul>
                    </div>
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html:t('cleo_functionality')}} />
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Shared_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Assigned_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Reservation_Mode') + '<br />(' + t('networked_locks_only') + ')'}}/>
                      </ul>
                      <h3 dangerouslySetInnerHTML={{__html:t('cleo_installation')}} />
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('designed_for_new_stationary_furniture_installations')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_3')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('power_and_data_wired_on_the_same_cable_with _a_rj45_connector')}}/>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={'locking-finish-items'}>
                  <StaticImage
                      src="../../../../images/locks/product-pages/pivot/pivot-collage.png"
                      loading={'lazy'}
                      width={520}
                      height={482}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Aspire famiily"
                      className={'Pivot'}
                  />
                </div>
              </div>
            </div>
          </section>
          <div class="section-delimeter container"/>
          <section className="page-management">
            <BANManagement product={'Pivot'}/>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="faq-section">
            <FaqSection/>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
